import styled from '@emotion/styled'
import { Select } from 'app/components/Common/Select'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo, useState } from 'react'

import { Competition, Props as CompetitionProps } from './Competition'

export interface Props {
  competitions: CompetitionProps[]
  languageCode: string
}

export const Competitions = memo(function Competitions({
  competitions,
  languageCode,
}: Props) {
  if (competitions.length < 1) {
    return null
  }

  const [activeMonth, setActiveMonth] = useState('')
  const [activeYear, setActiveYear] = useState('')

  const month = compact(
    competitions
      .sort(
        (a: any, b: any) =>
          new Date(a.start_date).getMonth() - new Date(b.start_date).getMonth(),
      )
      .map((e) =>
        e.start_date
          ? {
              ['value']: new Date(e.start_date).toLocaleDateString(
                languageCode,
                {
                  month: 'long',
                },
              ),
            }
          : null,
      ),
  )

  const year = compact(
    competitions
      .sort(
        (a: any, b: any) =>
          new Date(a.start_date).getFullYear() -
          new Date(b.start_date).getFullYear(),
      )
      .map((e) =>
        e.start_date
          ? {
              ['value']: new Date(e.start_date).toLocaleDateString(
                languageCode,
                {
                  year: 'numeric',
                },
              ),
            }
          : null,
      ),
  )

  return (
    <Container>
      <Filters dial={5} row wrap>
        <Select
          className="month"
          options={[
            {
              value: '',
              label: useVocabularyData('month', languageCode),
            },
            ...uniqBy(month, 'value'),
          ]}
          onChange={(e) => setActiveMonth(e.target.value)}
          name="month"
        />
        <Select
          options={[
            {
              value: '',
              label: useVocabularyData('year', languageCode),
            },
            ...uniqBy(year, 'value'),
          ]}
          onChange={(e) => setActiveYear(e.target.value)}
          name="year"
        />
      </Filters>

      <List>
        {competitions
          .sort((a, b) =>
            (a.start_date ? a.start_date : 1) >
            (b.start_date ? b.start_date : 0)
              ? 1
              : -1,
          )
          .map((item: any, index) => (
            <Competition
              className={
                (activeMonth ===
                  new Date(item.start_date).toLocaleDateString(languageCode, {
                    month: 'long',
                  }) ||
                  activeMonth === '') &&
                (activeYear ===
                  new Date(item.start_date).toLocaleDateString(languageCode, {
                    year: 'numeric',
                  }) ||
                  activeYear === '')
                  ? 'visible'
                  : undefined
              }
              key={index}
              {...item}
            />
          ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 6.25rem 12.153vw 14.375rem;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 767px) {
    padding-top: 3.75rem;
    padding-bottom: 7.5rem;
  }
`

const Filters = styled(FlexBox)`
  select {
    width: auto;
    min-width: 12.5rem;
    margin: 0 0.75rem;
    &.month {
      text-transform: capitalize;
    }
  }

  @media (max-width: 767px) {
    select {
      width: 100%;
      margin: 0.125rem 0 0;
    }
  }
`

const List = styled.div``
